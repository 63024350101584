import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Strong, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Elite Turf
			</title>
			<meta name={"description"} content={"Ідеальна подача для кожного гравця"} />
			<meta property={"og:title"} content={"Elite Turf"} />
			<meta property={"og:description"} content={"Ідеальна подача для кожного гравця"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-2.jpg?v=2024-06-19T12:06:47.309Z"} />
		</Helmet>
		<Components.Contact>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="icon1" />
		</Components.Contact>
		<Components.Nav>
			<Override slot="link4" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Nav>
		<Components.Hero background="linear-gradient(0deg,rgba(0,0,0,0) 0%,#081c0f 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/1-1.jpg?v=2024-06-19T12:06:47.298Z) 50% 50% /cover repeat scroll padding-box">
			<Override slot="button" />
			<Override slot="text1">
				Ласкаво просимо до Elite Turf, найкращого місця для футбольних ентузіастів, які хочуть грати на найкращому доступному газоні.
			</Override>
			<Override slot="text3" />
			<Override slot="text2">
				Незалежно від того, організовуєте ви змагальний матч, товариську гру чи корпоративний захід, наш заклад створений, щоб задовольнити всі ваші потреби та подарувати незабутні враження від гри.Ми розуміємо, наскільки важливою є справність вашої техніки у повсякденному житті, тому наша команда професіоналів завжди готова допомогти вам швидко та якісно. Наш сервісний центр оснащений найсучаснішим обладнанням, що дозволяє виконувати діагностику та ремонт будь-якої складності. Ми гарантуємо індивідуальний підхід до кожного клієнта та високий рівень сервісу.
			</Override>
			<Override slot="text" />
			<Override slot="box1" display="none" />
			<Override slot="box" width="70%" />
		</Components.Hero>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
				>
					Про Elite Turf
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					У Elite Turf ми пишаємося тим, що пропонуємо сучасне футбольне поле, яке обслуговує гравців усіх рівнів. На нашому полі використовуються найновіші технології штучного покриття, що забезпечує оптимальну продуктивність, безпеку та задоволення. Ми розуміємо пристрасть до футболу, і наша мета — створити середовище найвищого рівня, де можна отримати максимальне задоволення від гри.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-1.jpg?v=2024-06-19T12:06:47.267Z"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					srcSet="https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-1.jpg?v=2024-06-19T12%3A06%3A47.267Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-1.jpg?v=2024-06-19T12%3A06%3A47.267Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-1.jpg?v=2024-06-19T12%3A06%3A47.267Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-1.jpg?v=2024-06-19T12%3A06%3A47.267Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-1.jpg?v=2024-06-19T12%3A06%3A47.267Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-1.jpg?v=2024-06-19T12%3A06%3A47.267Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-1.jpg?v=2024-06-19T12%3A06%3A47.267Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-8"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="5%"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="35%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 8px 0px"
					font="--lead"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 16px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					<Strong
						sm-text-align="left"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
						color="--darkL1"
					>
						Наші переваги
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Чому варто вибрати Elite Turf?
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Незалежно від того, чи потрібна вам робота на годину, день або повторюваний проміжок часу, наші гнучкі варіанти бронювання задовольнять усі ваші потреби щодо планування.
				</Text>
			</Box>
			<Box
				width="60%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px 32px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				md-grid-gap="36px "
			>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Якість газону преміум-класу
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Наше поле обладнано високоякісним штучним газоном, схваленим FIFA, що забезпечує постійне та безпечне ігрове покриття, яке імітує найкращі поля з натуральної трави.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Зручне розташування
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Розташований у чудовому місці, до Elite Turf легко дістатися з різних частин міста, що робить його ідеальним вибором як для місцевих команд, так і для гостей.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Сучасні зручності
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Ми пропонуємо чисті та просторі роздягальні, доглянуті туалети та зручні зони для глядачів, щоб покращити ваші враження.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							Професійне технічне обслуговування
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Наш відданий персонал гарантує, що поле та об’єкти завжди будуть у найкращому стані, щоб ви могли насолоджуватися грою без проблем.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0 100px 0" quarkly-title="List-5">
			<Override slot="SectionContent" max-width="100%" width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
				align-items="center"
				align-self="center"
				justify-items="center"
				text-align="center"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px auto 25px auto" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Місце для кожного
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Elite Turf — це не просто футбольне поле, це осередок спільноти, де гравці, сім’ї та друзі можуть зібратися разом, щоб поділитися своєю любов’ю до гри. Наш заклад відкритий для команд, клубів і окремих осіб, пропонуючи гнучкі варіанти оренди відповідно до будь-якого графіка. Завдяки чудовому освітленню, добре доглянутим майданчикам і просторій парковкі ми полегшуємо вам зосередитися на найважливішому – грі у футбол.
					</Text>
				</Box>
				<Image
					src="https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-5%20%281%29.jpg?v=2024-06-19T12:06:47.275Z"
					display="block"
					width="100%"
					md-order="-1"
					srcSet="https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-5%20%281%29.jpg?v=2024-06-19T12%3A06%3A47.275Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-5%20%281%29.jpg?v=2024-06-19T12%3A06%3A47.275Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-5%20%281%29.jpg?v=2024-06-19T12%3A06%3A47.275Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-5%20%281%29.jpg?v=2024-06-19T12%3A06%3A47.275Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-5%20%281%29.jpg?v=2024-06-19T12%3A06%3A47.275Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-5%20%281%29.jpg?v=2024-06-19T12%3A06%3A47.275Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-5%20%281%29.jpg?v=2024-06-19T12%3A06%3A47.275Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-4.jpg?v=2024-06-19T12:06:47.265Z"
					display="block"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-4.jpg?v=2024-06-19T12%3A06%3A47.265Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-4.jpg?v=2024-06-19T12%3A06%3A47.265Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-4.jpg?v=2024-06-19T12%3A06%3A47.265Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-4.jpg?v=2024-06-19T12%3A06%3A47.265Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-4.jpg?v=2024-06-19T12%3A06%3A47.265Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-4.jpg?v=2024-06-19T12%3A06%3A47.265Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6672ad768e2e8e00217d19da/images/2-4.jpg?v=2024-06-19T12%3A06%3A47.265Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px auto 25px auto" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Забронюйте гру сьогодні
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Не чекайте, щоб відчути найкраще футбольне поле в місті. Незалежно від того, чи плануєте ви одноразову подію чи шукаєте регулярне місце для гри, Elite Turf — це ваше місце призначення. Зв’яжіться з нами сьогодні, щоб забронювати час на полі та приєднатися до спільноти футбольних ентузіастів, які обирають Elite Turf для своїх ігор.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Nav>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link4" />
		</Components.Nav>
		<Components.Contact />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});